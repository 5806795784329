import React from 'react';
import Header from '../components/header';
import { Grid, makeStyles } from '@material-ui/core';
import { LinedHeader } from '../components/styledComponents';
import Footer from '../components/footer';
import styled from 'styled-components'
import ThemeCollection from '../components/ThemeCollection';


const useStyles = makeStyles((theme) => ({
  treats: {
    padding: '0 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  }
}));


const Treats = () => {
  const classes = useStyles();

  return (
    <div>
      <Header title="Treats" />
      <Grid container>
        <Grid item xs={12} justify="center" className={classes.treats}>
          <LinedHeader>Treats</LinedHeader>
          {/* <StyledLink to="/menu">Click here to see our menu & pricing</StyledLink> */}

          <ThemeCollection
            theme_name="Cupcakes"
            type="other"
            items={[
              "cupcakes22",
              "cupcakes26",
              "cupcakes11",
              "cupcakes24",
              "cupcakes15",
              "cupcakes14",
              "cupcakes16",
              "cupcakes12",
              "cupcakes17",
              "cupcakes13",
              "cupcakes18",
              "cupcakes1",
              "cupcakes19",
              "cupcakes10",
              "cupcakes2",
              "cupcakes4",
              "cupcakes5",
              "cupcakes6",
              "cupcakes7",
              "cupcakes8",
              "cupcakes9",
              "cupcakes20",
              "cupcakes21",
            ]}
          />

          <ThemeCollection
            theme_name="Cakepops"
            type="other"
            items={[
              "cakepop13",
              "cakepop14",
              "cakepop15",
              "cakepop16",
              "cakepop7",
              "cakepop8",
              "cakepop1",
              "cakepop2",
              "cakepop4",
              "cakepop6",
              "cakepop3",
              "cakepop5",
              "cakepop9",
              "cakepop11",
              "cakepop12",
              "cakepop10",
              "cakepop17",
            ]}
          />

          <ThemeCollection
            theme_name="Chocolate Covered Treats"
            type="other"
            items={[
              "strawberries2",
              "cocoabombs1",
              "cocoabombs4",
              "strawberries1",
              "cocoabombs2",
              "cocoabombs6",
              "oreos1",
              "cocoabombs9",
              "oreos3",
              "cocoabombs10",
              "oreos4",
            ]}
          />

          <ThemeCollection
            theme_name="Treat Boxes"
            type="other"
            items={[
              "treatbox1",
              "treatbox3",
              "treatbox4",
              "treatbox8",
              "treatbox11",
              "treatbox2",
              "treatbox12",
            ]}
          />

        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Treats;
